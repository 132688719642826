
import { computed, ComputedRef, defineAsyncComponent, defineComponent, ref } from "vue";
import { useFoldersStore } from "@/stores/folders";
import { storeToRefs } from "pinia";
import File from '@/types/File';
import api from '@/api/spaceMountainAPI';
import {AxiosResponse} from "axios";
import { arrayHasBasedOnId, arrayToggleBaseOnId, isActionAvailable } from '@/functions/utils';

export default defineComponent({
  name: "Files",
  components: {
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    CardButton: defineAsyncComponent(() => import('@/components/CardButton.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Card: defineAsyncComponent(() => import('@/components/Card.vue')),
    UploadFile: defineAsyncComponent(() => import('@/modales/UploadFile.vue')),
    TableItem: defineAsyncComponent(() => import('@/components/TableItem.vue')),
    TableItemSkeleton: defineAsyncComponent(() => import('@/components/TableItemSkeleton.vue')),
    Checkbox: defineAsyncComponent(() => import('@/components/Checkbox.vue')),
  },
  setup() {
    //STORE folder
    const foldersStore = useFoldersStore();
    const { currentFolder, folderLoader } = storeToRefs(foldersStore);

    //DATA
    const isUploadFileOpen = ref(false);
    const filesCheck = ref<Array<any>>([]);

    //FILTERS
    const searchTerm = ref('');
    const filesToDisplay: ComputedRef<Array<Object>> = computed(() => {
      let result = currentFolder.value.files;
      if (searchTerm.value !== '') result = result.filter((file: File) => file.fileName.includes(searchTerm.value));
      return result.map((item: any) => {
        const logo = 'fas fa-file-alt';
        const fields = [
          { title: 'Nom', value: item.fileName },
          { title: 'Date', value: new Date(item.creationDate).toLocaleString() },
        ];
        const actions = [
          { icon: 'fas fa-file-download', text: 'Télécharger', action: 'download' },
          { icon: 'fas fa-trash', text: 'Supprimer', action: 'delete' }
        ];
        return { id: item.id, nbFile: item.nbFile, logo: logo, fields: fields, actions: actions };
      });
    });

    const toggleFilesCheck = () => {
      if (filesCheck.value.length === 0) {
        filesToDisplay.value.forEach((folder: any) => arrayToggleBaseOnId(filesCheck.value, folder));
      } else {
        filesCheck.value = [];
      }
    };

    const uploadFileSuccess = () => {
      isUploadFileOpen.value = false;
      foldersStore.getFolderById(currentFolder.value.id);
    };

    const deleteFile = (fileId: string) => {
      foldersStore.deleteFile(fileId);
    };

    const deleteAll = () => {
      filesCheck.value.forEach((item) => deleteFile(item.id));
    };

    const openFile = (fileId: string) => {
      api.downloadFile(fileId).then((response: AxiosResponse) => {
        const binaryString = window.atob(response.data.contentB64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        const arrayBuffer = bytes.map((byte, i) => binaryString.charCodeAt(i));
        const blob = new Blob([arrayBuffer]);
        const fileName = response.data.fileName;
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
    }

    const openAllFiles = () => {
      filesCheck.value.forEach((item) => openFile(item.id));
    };

    return { currentFolder, arrayHasBasedOnId, arrayToggleBaseOnId, filesToDisplay, folderLoader, toggleFilesCheck,
      isActionAvailable, filesCheck, searchTerm, openFile, openAllFiles, isUploadFileOpen, uploadFileSuccess, deleteFile, deleteAll };
  },
});
